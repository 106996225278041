
import * as __star__ from '@clayui/pagination';

const {
default: __default__,
ClayPaginationWithBasicItems,
Ellipsis,
Item,
Pagination,
__esModule
} = __star__;

export {
ClayPaginationWithBasicItems,
Ellipsis,
Item,
Pagination,
__esModule
};
export default __default__;
